import './Company.scss';
import React from 'react';
import cx from 'classnames';
import {SubAction} from "../../common/action-buttons";
import {uiState} from "../../common/ui-store";

export const Company = ()=>(
	<section id="company" className={cx("company")}>
		<div className="container">

			<div className="row mb-3">
				<div className="col d-flex justify-content-center">
					{/*<a href="#" className="btn btn-color btn-lg">Get a Quote!</a>*/}
					{/*<button type="button" className="btn btn-primary">Get a Quote!</button>*/}
					<SubAction onClick={()=>uiState.toggleQuote()}>Get a Quote!</SubAction>
				</div>
			</div>

			<div className="row">
				<div className="col-md-9">
					<h1>Green Marine <small className="text-muted">Ltd</small></h1>
					<p>
						Green Marine Ltd. is a joint venture of Port of Tallinn Ltd. and NT Marine Ltd. GM economical activity is targeted on developing of environmental services for ports and ships, on sea pollution prevention, localization and liquidation issues in accordance with international standards.
					</p>
					<p>
						GM also coordinates waste management services offering to the ships within the ports of Port of Tallinn (Muuga Port, Old Port, Paljassaare Harbor, Paldiski South Harbor and Saaremaa Harbor).
					</p>
					<p>
						GM guided by the waste management service, "Waste" from the definition of <i className="fa fa-paragraph"></i>13 (including organization of waste collection, transport, recovery and disposal).
					</p>
				</div>

				<div className="col-md-3">
					<img className="img-fluid" src="images/ack/bisnode/portrait_b.png" />
					<img className="img-fluid" src="images/ack/bisnode/portrait_w.png" />
				</div>
			</div>

			<hr/>

			<div className="row">
				<div className="col-md">
					<div className="services-item">
						<i className="fa fa-recycle fa-3x text-color"></i>
						<div className="services-item-desc">
							<h3 className="primary-font">Waste Sorting</h3>
							<p className="text-muted">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id ipsum varius, tincidunt odio nec, placerat enim.
							</p>
						</div>
					</div>
				</div>
				<div className="col-md">
					<div className="services-item">
						<i className="fa fa-shipping-fast fa-3x text-color"></i>
						<div className="services-item-desc">
							<h3 className="primary-font">Services</h3>
							<p className="text-muted">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id ipsum varius, tincidunt odio nec, placerat enim.
							</p>
						</div>
					</div>
				</div>
				<div className="col-md">
					<div className="services-item">
						<i className="fa fa-ship fa-3x text-color"></i>
						<div className="services-item-desc">
							<h3 className="primary-font">Clean Sea</h3>
							<p className="text-muted">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id ipsum varius, tincidunt odio nec, placerat enim.
							</p>
						</div>
					</div>
				</div>
				<div className="col-md">
					<div className="services-item">
						<i className="fa fa-paragraph fa-3x text-color"></i>
						<div className="services-item-desc">
							<h3 className="primary-font">The Law</h3>
							<p className="text-muted">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id ipsum varius, tincidunt odio nec, placerat enim.
							</p>
						</div>
					</div>
				</div>
			</div>

		</div>
	</section>
)





