import React from 'react';
import {Carousel} from "../carousel/Carousel";
import {Company} from "./about/Company";
import {Intro} from "./about/Intro";
import {Mission} from "./about/Mission";
import {Lics} from "./about/Lics";
import {Clients} from "./about/Clients";
import {Acknowledgements} from "./about/Acknowledgements";

export const About = () => (
	<React.Fragment>
		<Carousel/>
		<Company/>
		<Intro/>
		<Mission/>
		<Acknowledgements/>
		<Clients/>
		<Lics/>
	</React.Fragment>
)
