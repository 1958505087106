import './Clients.scss';
import React from 'react';
import {SectionTitle} from "../SectionTitle";

const list = ['image3702.png','image3728.png','image3741.png','image3769.png','image3782.png','image3795.png','image3808.png','image3821.png','image3847.png','image3860.png','image3873.png','image3886.png','image3899.png','image3912.png','image3925.png','image3938.png','image3951.png','image3964.png','image3977.png','image3990.png','image4003.png','image4016.png']

export const Clients = () => (
	<section id="clients">
		<div className='clients'>
			<div className="container">
				<div className="row">
					<div className="col">
						<SectionTitle main='Clients' sub='and Partners'/>
					</div>
				</div>

				<div className="row cont">
					<div className="client-strip">
						{list.map(a=><img key={a} src={`images/clients/${a}`} className="client-logo" alt=""/>)}
					</div>
				</div>
			</div>
		</div>
	</section>
)






