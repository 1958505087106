import {IMenuDesc} from "./defs";
import {grey, orange, blueGrey, red, purple, deepPurple} from "@material-ui/core/colors";

function fa(icon){
	return `fa fa-${icon} fa-fw`
}

export const sideIconStyle = {
	color: blueGrey[700]
}
export const topIconStyle = {
	color: grey[600]
}

export const icoStyleSide=(m:IMenuDesc)=>m.col ? {color:m.col} : sideIconStyle;
export const icoStyleTop=(m:IMenuDesc)=>m.col ? {color:m.col} : topIconStyle;


export const navData:IMenuDesc[] = [
	{k:'about', title:'About', icon:fa('copyright'), col:orange[900],sub: [
		{k:'intro', title: 'Introduction', icon:fa('play-circle')},
		{k:'mission', title: 'Mission and Vision', icon:fa('ad')},
		{k:'ack', title: 'Acknowledgements', icon:fa('leaf')},
		{k:'clients', title: 'Client and Partners', icon:fa('handshake')},
		// {k:'vision', title: 'Vision', icon:fa('recycle')},
		// {k:'lics', title: 'Permits and Licenses', icon:fa('play-circle')},
		{k:'lics', title: 'Certification', icon:fa('certificate')},
		// {k:'cert', title: 'ISO Certificate', icon:fa('certificate')},
	]},
	{k:'services',title:'Services', icon:fa('truck')},
	{k:'mts', title:'Oil Waste (MTS)', icon:"fa fa-trademark", col:deepPurple[400],sub:[
	// {k:'mts', title:'MTS', icon:"fa fa-industry",sub:[
		{k:'mts-overview',title:'MTS overview', icon:fa('copyright')},
		{k:'mts-presentation',title:'Presentation', icon:fa('poll')},
		{k:'mts-handling',title:'Handling scheme', icon:fa('truck')},
	]},
	{k:'gallery',title:'Gallery', icon:"fa fa-image"},
	{k:'contacts',title:'Contacts',icon:fa('address-card')}
]


export const navDataMatIcons:IMenuDesc[] = [
	{k:'about', title:'About',sub: [
		{k:'intro', title: 'Introduction', icon:'code'},
		{k:'mission', title: 'Mission', icon:'alarm_on'},
		{k:'vision', title: 'Vision', icon:'card_giftcard'},
		{k:'lics', title: 'Permits and Licenses', icon:'play_circle_outline'},
		{k:'cert', title: 'ISO Certificate', icon:fa('certificate')},
		{k:'policy', title: 'Environmental Policy', icon:'leaf'},
	]},
	{k:'services',title:'Services', icon:'build'},
	{k:'mts', title:'Oil Waste Handling (MTS)', icon:"fa fa-industry",sub:[
		// {k:'mts', title:'MTS', icon:"fa fa-industry",sub:[
		{k:'mts-overview',title: 'MTS overview'},
		{k:'mts-presentation',title: 'Presentation'},
		{k:'mts-handling',title: 'Handling scheme'},
	]},
	{k:'gallery',title:'Gallery', icon:"fa fa-image"},
	{k:'contacts',title:'Contacts'}
]
