import * as React from "react";
import Button from "@material-ui/core/Button";
import {t} from "../../common/lang";
import {observer} from "mobx-react";

const style = {
	// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
	// borderRadius: 3,
	// border: 0,
	color: 'white',
	height: 51,
	padding: '0px 30px',
	boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
	borderRadius: 2
};

export const Slide1 = observer((props) => (
	<div className="carousel-item active">
		<div className="jumbotron first">
			<div className="container">
				<div className="row">
					<div className="col-xs-2 col-sm-4 col-lg-8"></div>
					<div className="col-xs-10 col-sm-8 col-lg-4">
						<h1 className="orange animated fadeInRightBig"><i>Beautiful</i><br/><i>and Clean</i></h1>
						<p className="lead animated fadeInRightBig delay-1">
							<i>{t('Our collected waste recycling rate')}</i><br/>
							<i>has been over 90% for years</i><br/>
						</p>
						{/*<a className="btn btn-color animated fadeInRightBig delay-2">More</a>*/}
						<Button className="btn btn-color animated fadeInLeftBig delay-2" variant="contained" style={style} color="secondary" size="large">
							More
						</Button>
					</div>
					{/*@*<div className="col-sm-6 hidden-xs">*/}
					{/*<img className="img-responsive" src="images/gm-logo.png" alt="...">*/}
					{/*</div>*@*/}
				</div>
			</div>
		</div>
	</div>
));

