import React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {blue, orange, purple} from "@material-ui/core/colors";
import {slideButton} from "../common/styles";

const style = {
	...slideButton,
	background: purple[800],
};

export const MainAction = (props)=>(
	<div>
		<Button style={style} variant="contained" color="secondary" size="large" onClick={props.onClick}>
			{props.children || 'Info'}
			{/*<Icon className="ml-2">send</Icon>*/}
		</Button>
	</div>
);


const styleSub = {
	...slideButton,
	boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
	background: orange[900],
};

export const SubAction = ({children,onClick})=>(
	<Button style={styleSub} variant="contained" color="secondary" size="large" onClick={onClick}>
		{children}
		<Icon className="ml-2">info</Icon>
	</Button>
)
