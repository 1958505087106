import * as React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {red, deepOrange, orange, purple, indigo} from "@material-ui/core/colors";
import {slideButton} from "../../common/styles";

const style = {
	...slideButton,
	background: deepOrange['A400'],
	boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
};

export const Slide5 = (props) => (
	<div className="carousel-item">
		<div className="jumbotron _5">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<h1 className="text-center animated fadeInDown"><i>Our mission is</i></h1>
						<p className="lead text-center animated fadeInRightBig">
							<i className="animated fadeInRightBig delay-2">to protect evironment</i><br/>
							<i className="animated fadeInRightBig delay-3">and sea ecology</i><br/>
						</p>
						<div className="text-center animated fadeInDown delay-4">
							<Button style={style} variant="contained" color="secondary" size="large">
								<span>More</span>
								<Icon className="ml-2">info</Icon>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

