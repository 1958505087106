import {observer} from "mobx-react";
import React, {Component} from "react";
import {SectionTitle} from "../SectionTitle";

@observer
export class WasteFee extends Component {
	render() {
		return (
			<section id="fee">
				<div className='fee'>
					<div className="container">
						<div className="row">
							<div className="col">
								<SectionTitle main='Services' sub='under the waste fee'/>
							</div>
						</div>

						<div className="row">
						</div>
					</div>
				</div>
			</section>
		);
	}
}
