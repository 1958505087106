import './Intro.scss';
import React, {Component} from 'react';
import {SectionTitle} from "../SectionTitle";
import {createStyles, ExpansionPanel} from "@material-ui/core";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import withStyles from "@material-ui/core/styles/withStyles";
import {FancyLink} from "../../common/fancy-link/FancyLink";

declare var YT;

export class Intro extends Component {
	state = {
		expanded:false
	}
  handleChange = (event, expanded) => {
    this.setState({expanded});
  };

	componentDidMount(){
	}
	vid() {
		return (
			<div className="embed-responsive embed-responsive-16by9">
				<iframe className="embed-responsive-item" src="//www.youtube.com/embed/zcXQollLKdU?loop=1&autoplay=1&disablekb=1&autohide=1&modestbranding=0&autoplay=1&rel=0&controls=0&showinfo=0&autohide=1&mute=1" allowFullScreen />
			</div>
		)
	}
	vidPan() {
		const {expanded} = this.state;
		const expPan = createStyles({
			expanded:{margin:'auto'}
		})
		const p = props => (
			<ExpansionPanel square {...props} expanded={expanded} onChange={this.handleChange}>
				<ExpansionPanelSummary>
					<h4>Watch Video <small>about us</small></h4>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					{expanded ? this.vid() : <div />}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
		const Emm=withStyles(expPan)(p);
		return (
			<Emm/>
		);
	}
	render() {
		return (
			<section id="intro">
				<div className="intro">
					<div className="video-background">
						{this.vid()}
					</div>
					<div className="cover"></div>

					<div className="container">
						<div className="row">
							<div className="col">
								<SectionTitle main='Short' sub='introduction'/>

								<p>Green Marine Ltd. (GM) is a daughter company of the Port of Tallinn whose mission is to protect marine ecology and the environment by offering a comprehensive waste management service for ships and port operators. We do not only manage waste, but train crew members to sort waste. This helps us to increase environmental awareness and recycling percentage.</p>
								<p>One of our everyday goals and aims of work is to build Company, efficient in localizing and liquidating possible marine pollutions which would be equipped with modern and innovative technical solutions to act in right time and in right place.</p>
								<p>We also fulfil environmental politics of the GreenPort, verifying Estonia’s relation to the environmental protecting North region.</p>

								<p>We are certified by <b>ISO 14001, ISO 9001 and AQAP 2110</b> (NATO’s standard for quality assurance systems).</p>
								<p>We serve our clients 24 / 7 / 365</p>

							</div>
						</div>

						<div className="row">
							<div className="col-md-8">
							</div>
							<div className="col-md-4">
								<FancyLink title="Presentation" desc="pdf presentation" ico="file-image" pdf="pdf/gm_eng.pdf" />
							</div>
						</div>
					</div>
				</div>

				{this.vidPan()}

			</section>
		);
	}
}





