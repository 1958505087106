import './Header.scss';
import * as React from "react";
import {navData} from "../common/nav-data";
import {IDrawerProps} from "../common/defs";
import IconButton from "@material-ui/core/IconButton";
import {Langbar} from "./Langbar";
import AppBar from "@material-ui/core/AppBar";
import {TopMenu} from "./TopMenu";
import {uiState} from "../common/ui-store";
import {observer} from "mobx-react";
import {HashLink} from 'react-router-hash-link';

@observer export class Header extends React.Component<IDrawerProps,any> {
	state = {
		el: null,
	};
	render(){
		return(
			<div className="fixed-top">
				<Langbar show={uiState.langNav}/>

				<AppBar position="static" color="default">
					<div className="container tool-bar">
						<HashLink to='about#' className="navbar-brand">
							<img src="/gm-logo2.png" alt="" />
						</HashLink>

						<div className="navbar-nav">
							{navData.map(a=><TopMenu key={a.k} m={a} />)}
						</div>

						<div className="navbar-toggler">
							<IconButton onClick={this.props.onToggle}>
								<i className="fa fa-bars" aria-hidden="true"></i>
							</IconButton>
						</div>
					</div>
				</AppBar>
			</div>
		);
	}
}
