import './sidebar.scss';
import React from "react";
import {IDrawerProps} from "../common/defs";
import Drawer from "@material-ui/core/Drawer";
import {List, ListItem, ListItemText} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Fab from "@material-ui/core/Fab";
import {icoStyleSide, navData, topIconStyle} from "../common/nav-data";
import classNames from 'classnames';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {HashLink} from 'react-router-hash-link';
import {log} from "../common/utils";
import {uiState} from "../common/ui-store";

const style: any = {
	position:"absolute",
	top:'10px',
	right:'10px'
};

class SideItem extends React.Component<any,any>{
	state = {open: true};
	click = ()=>{
		this.setState({open: !this.state.open});
		const {m} = this.props;
		if (!m.sub) {
			uiState.toggleSidebar();
		}
	};
	open(){return <i className="fa fa-chevron-down"/>;}
	close(){return <i className="fa fa-chevron-up"/>;}

	navItem(p,m,cls){
		const menu = (
			<ListItem button onClick={this.click}>
				<span className={cls}></span>
				{m.icon && <ListItemIcon><i className={classNames(m.icon)} style={icoStyleSide(m)}/></ListItemIcon>}
				<ListItemText primary={m.title} />
				{m.sub && (this.state.open ? this.open() : this.close())}
			</ListItem>
		);

		if (!p && !m.sub){
			return <HashLink to={`${m.k}`}>{menu}</HashLink>
		}
		if (!p && m.sub){
			return menu;
		}
		return <HashLink to={`${p.k}#${m.k}`}>{menu}</HashLink>;
	}
	render(){
		const {parent,m,cls} = this.props;
		return (
			<React.Fragment>
				{this.navItem(parent,m,cls)}

				{/*<ListItem button onClick={this.click}>*/}
					{/*<span className={cls}></span>*/}
					{/*{m.icon && <ListItemIcon><i className={classNames(m.icon)} style={icoStyleSide(m)}/></ListItemIcon>}*/}
					{/*<ListItemText primary={m.title} />*/}
					{/*{m.sub && (this.state.open ? this.open() : this.close())}*/}
				{/*</ListItem>*/}

				{m.sub && <Collapse in={this.state.open} timeout="auto" unmountOnExit>
					<List>
						{m.sub.map(a=><SideItem key={a.k} parent={m} m={a} cls="ml-3" />)}
					</List>
				</Collapse>}
			</React.Fragment>
		)
	}
}

export class Sidebar extends React.Component<IDrawerProps,any> {
	state = { open: true };

	constructor(props) {
		super(props)
	}
	render(){
		return (
			<Drawer open={this.props.open} onClose={this.props.onToggle}>
				<div className="sidebar">
					<div className="header">
						<img src="/images/slaid_1.jpg" className="side-img img-fluid" alt="Responsive image" />

						<div className="middle">
						</div>

						<Fab size="small" color="secondary" style={style} onClick={this.props.onToggle}>
							<i className="fa fa-times"/>
						</Fab>
					</div>

					<List disablePadding className="mt-3">
						{navData.map(a=><SideItem key={a.k} m={a} />)}
					</List>
				</div>
			</Drawer>
		)
	}
}
