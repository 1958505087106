import './FancyLink.scss'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";

export const FancyLink = ({title, desc, ico, pdf}) => (
	<a href={pdf} className="card fancy">
		<Card className="lic-card" style={{background:'none'}}>
			<CardContent className="content">
				<h5>{title}</h5>
				<small className="text-muted">{desc}</small>
			</CardContent>
			<div className="ico">
				<i className={`fas fa-${ico}`}></i>
			</div>
			{/*<CardMedia*/}
			{/*	className="cover"*/}
			{/*	image={img}*/}
			{/*	title="Live from space album cover"*/}
			{/*/>*/}
		</Card>
	</a>
);
