import React from 'react';
import {Carousel} from "../carousel/Carousel";
import {WasteFee} from "./services/WasteFee";
import {ExtraFee} from "./services/ExtraFee";

export const Services = () => (
	<React.Fragment>
		<Carousel/>
		<WasteFee/>
		<ExtraFee/>
	</React.Fragment>
)
