import * as React from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {IMenuDesc} from "../common/defs";
import {green} from "@material-ui/core/colors";
import classNames from 'classnames';
import {icoStyleTop, topIconStyle} from "../common/nav-data";
import {HashLink} from 'react-router-hash-link';

export type GmenuData = {
	m:IMenuDesc
}
const menuStyle = {
	style: {
		borderRadius: 2,
	}
}

const styles = theme => ({
	root: {
		padding: theme.spacing.unit,
		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.secondary.main,
		},
		[theme.breakpoints.up('md')]: {
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.up('lg')]: {
			backgroundColor: green[500],
		},
	},
});

export class TopMenu extends React.Component<GmenuData,any> {
	state = {el: null};
	constructor(props){
		super(props)
	}
	handleClick = event=>{
		this.setState({el: event.currentTarget});
	};
	handleClose = ()=>{
		this.setState({el:null});
	};
	children(m:IMenuDesc, el){
		return m.sub && (
			<Menu anchorEl={el} open={Boolean(el)} onClose={this.handleClose} PaperProps={menuStyle}>
				{m.sub.map(a=>
					<MenuItem className="menu-item" key={a.k} onClick={this.handleClose}>
						<HashLink to={`${m.k}#${a.k}`}>
							{/*<Icon className="text-muted mr-3">{a.icon || 'send'}</Icon>*/}
							<i className={classNames('mr-2', a.icon)} style={topIconStyle}></i>
							{a.title}
						</HashLink>
					</MenuItem>
				)}
			</Menu>
		)
	}
	menuButton(m){
		const {title} = m;
		const shortTit = '';
		if (m.sub) {
			return (
				<Button className="rounded-0 align-self-stretch" color="inherit" onClick={this.handleClick}>
					{m.icon && <i className={classNames('mr-2', m.icon)} style={icoStyleTop(m)}></i>}
					<span className={shortTit}>{title}</span>
				</Button>
			)
		}
		return (
			<Button className="rounded-0 align-self-stretch" color="inherit" onClick={this.handleClick}>
				<HashLink to={m.k}>
					{m.icon && <i className={classNames('mr-2', m.icon)} style={icoStyleTop(m)}></i>}
					<span className={shortTit}>{title}</span>
				</HashLink>
			</Button>
		)
	}

	render(){
		const {el} = this.state;
		const {m} = this.props;
		return (
			<React.Fragment>
				{this.menuButton(m)}
				{this.children(m, el)}
			</React.Fragment>
		)
	}
}
