// export const Carousel = withStyles(styles)(
// const styles = createStyles({
// 	root: {
// 		// display: 'flex',
// 		// flexDirection: 'column',
// 		background: 'red'
// 	},
// });

export const slideButton = {
	// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
	// borderRadius: 3,
	// border: 0,
	// color: 'white',
	height: 51,
	padding: '0px 30px',
	boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
	borderRadius: 2
};
