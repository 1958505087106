import './App.scss';
import React, {Component} from 'react';
import {IAppState} from "../common/defs";
import {Sidebar} from "../sidebar/Sidebar";
// import {OverridesClassNamesStyled} from "../test/Override";
import {Header} from "../header/Header";
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import {Carousel} from "../carousel/Carousel";
import {jlog, log} from "../common/utils";
import {StickyFooter} from "../footer/StickyFooter";
import {Button} from "@material-ui/core";
import {observer} from "mobx-react";
import {uiState} from "../common/ui-store";
import {QForm} from "../quote/QForm";
import {About} from "../sections/About";
import {Services} from "../sections/Services";
const logo = require('./logo.svg');

const Index = ({onToggle}) => (
	<React.Fragment>
		<Carousel/>
		<div className="container">
			<div className="row">
				{/*<div className="col"><OverridesClassNamesStyled>oi blja</OverridesClassNamesStyled></div>*/}
				<div className="col">
					<Button color="secondary" onClick={onToggle}>
						toggle
					</Button>
				</div>
			</div>
		</div>
	</React.Fragment>
);

@observer export class App extends React.Component<any,IAppState> {
	arno=['tere'];

	constructor(props){
		super(props);
		this.state = {arno: this.arno, showSidebar:false};
	}
	tere(){
		log('tere');
	}
	toggleSidebar = ()=>{
		uiState.toggleSidebar();
	}
  handleScroll = () => {
    // lastScrollY = window.scrollY;
		//
    // if (!ticking) {
    //   window.requestAnimationFrame(() => {
    //     this.nav.current.style.top = `${lastScrollY}px`;
    //     ticking = false;
    //   });
		//
    //   ticking = true;
    // }
    if (window.scrollY > 200 && uiState.langNav) {
			uiState.showLangNav(false)
    } else if (window.scrollY < 200 && !uiState.langNav) {
    	uiState.showLangNav(true)
		}
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

	render() {
		return (
			<Router>
				<React.Fragment>
					<Sidebar open={uiState.showSidebar} onToggle={this.toggleSidebar}/>
					<Header onToggle={this.toggleSidebar}/>
					<div className="gm-wrapper">
						<Route path="/" exact component={About} />
						<Route path="/test" exact render={()=>Index({onToggle:this.toggleSidebar})}/>
						{/*/!*<Route path="/gallery/" component={Gallery}/>*!/*/}
						<Route path="/about" component={About} />
						<Route path="/services" component={Services} />
					</div>
					{/*<StickyFooter/>*/}
					<QForm open={uiState.showQuote}/>
				</React.Fragment>
			</Router>
		);
	}
}


export default App;
