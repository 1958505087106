import React from 'react';
import './carousel.scss';
import {Slide1} from "./slides/Slide1";
import {Slide2} from "./slides/Slide2";
import {Slide3} from "./slides/Slide3";
import {Slide4} from "./slides/Slide4";
import {Slide5} from "./slides/Slide5";


export class Carousel extends React.Component<any, any> {
	render() {
		return <div className="main-slideshow">
			<div id="main-slideshow" className="carousel slide" data-ride="carousel">
				<ol className="carousel-indicators">
					<li data-target="#main-slideshow" data-slide-to="0" className="active"></li>
					<li data-target="#main-slideshow" data-slide-to="1"></li>
					<li data-target="#main-slideshow" data-slide-to="2"></li>
					<li data-target="#main-slideshow" data-slide-to="3"></li>
					<li data-target="#main-slideshow" data-slide-to="4"></li>
				</ol>

				<div className="carousel-inner">
					<Slide1/>
					<Slide2/>
					<Slide3/>
					<Slide4/>
					<Slide5/>
				</div>

				<a className="carousel-control-prev slideshow-arrow slideshow-arrow-prev bg-hover-color" href="#main-slideshow" data-slide="prev">
					<i className="fa fa-angle-left"></i>
				</a>
				<a className="carousel-control-next slideshow-arrow slideshow-arrow-next bg-hover-color" href="#main-slideshow" data-slide="next">
					<i className="fa fa-angle-right"></i>
				</a>
			</div>
		</div>;
	}
}
