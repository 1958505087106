import {ee} from "./lang-ee";
import {ru} from "./lang-ru";
import {computed, observable} from "mobx";
import {jlog} from "./utils";

const dic = {
	'ee': ee,
	'ru': ru
};

class LangStore {
	constructor(){
		jlog('lang started')
	}

	@observable lang = 'en'
	@computed get oddOrEven() {
		return 0;
	}
}
export const ls = new LangStore();

export function setGlobLang(l){
	ls.lang = l;
}

export function t(s){
	if (dic[ls.lang] && dic[ls.lang][s]) {
		return dic[ls.lang][s];
	}
	return s;
}

