import React from "react";
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/es/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent";
import DialogContentText from "@material-ui/core/es/DialogContentText";
import TextField from "@material-ui/core/es/TextField";
import DialogActions from "@material-ui/core/es/DialogActions";
import Button from "@material-ui/core/es/Button";
import {uiState} from "../common/ui-store";

export class QForm extends React.Component<{open},any> {
	handleClose = ()=>uiState.toggleQuote();
	render(){
		return (
			<div>
				<Dialog open={this.props.open} onClose={this.handleClose}>
					<DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
					<DialogContent>
						<DialogContentText>
							To subscribe to this website, please enter your email address here. We will send
							updates occasionally.
						</DialogContentText>
						<TextField autoFocus margin="dense"
							id="name"
							label="Email Address"
							type="email"
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleClose} color="primary">
							Subscribe
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
