import React from 'react';
import {SectionTitle} from "../SectionTitle";

export const Mission = () => (
	<section id="mission">
		<div className="container">
			<div className="row">
				<div className="col">
					<SectionTitle main='Mission' sub='and Vision'/>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-9">
					<p>Our mission is marine ecology and environmental protection and we provide ships with an integrated waste management service in order to achieve this. We also have the resources required for the localisation and liquidation of marine pollution.</p>
					<p>The activities of AS Green Marine are aimed at the provision of a high-quality, environmentally friendly waste management service that meets the needs of our clients and at the creation of a working environment that meets all requirements.</p>
					<p>For this purpose, we offer the best possible logistic and technological solutions whilst considering the need to comprehensively reduce the burden placed on the environment by pollution.</p>

					<p>We proceed from the following principles:</p>

					<ul>
						<li>our work is organised in accordance with the laws and standards that regulate our activities;</li>
						<li>we advise and inform clients and cooperation partners about the standards established with laws and other legislative acts;</li>
						<li>we are constantly looking for new solutions and introducing the best possible technologies to guarantee a safe and resource-efficient waste management service;</li>
						<li>we support our partners in all environmentally friendly ventures;</li>
						<li>readiness to participate in the localisation and liquidation of marine pollution is a priority for us;</li>
						<li>we use every opportunity to have reusable waste recycled and make every effort to restrict waste depositing; and</li>
						<li>we use all the opportunities offered by feedback to assess our performance. We carry out regular reviews to guarantee that the company’s activities comply with the environmental policy.</li>
					</ul>

				</div>

				<div className="col-sm-3">
					<img className="img-fluid" src="images/mission-vision.png"></img>
					<img className="img-fluid" src="images/iso14001-small.jpg"></img>
					<img className="img-fluid" src="images/EEET2018-ENG-standard-logo.png"></img>
				</div>

			</div>


			<div className="row">
				<div className="col">
					<h4>Vision</h4>
					<p>Our vision is constantly clean Baltic Sea and its shores. To achieve this goal we train our employees, keep our equipment and machinery up-to-date and develop customer relations.</p>

				</div>
			</div>
		</div>
	</section>
)
