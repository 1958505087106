// import css from './SectionTitle.module.scss';
import './SectionTitle.scss';
import React from 'react';
import cx from 'classnames';

export const SectionTitle = ({main,sub})=>(
	<h2 className={cx('headline', 'text-color')}>
		<span className='border-color'>{main}</span>
		<small>{sub}</small>
	</h2>
)
