import React, {Component} from "react";
import {CardMedia, Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export class AckDialog extends Component<any> {
	handleClose = () => {
		const {toggle} = this.props;
		if (toggle){
			toggle();
		}
	};
	render() {
		const {open,img,title,pdf} = this.props;
		return (
			<div>
				<Dialog
					open={open}
					onClose={this.handleClose}
					maxWidth="sm"
					fullWidth={true}
				>
					<DialogTitle style={{padding:'15px'}} disableTypography={true}>
						<h5>{title}</h5>
					</DialogTitle>
					{img && <CardMedia onClick={this.handleClose} image={img} style={{height:'100vh', backgroundPosition:'top'}}/>}
					{/*<DialogContent>*/}
					{/*</DialogContent>*/}
					<DialogActions>
						{pdf && <Button onClick={this.handleClose} color="secondary" href={pdf}>
							{/*<CloudDo className={classes.rightIcon} />*/}
							<i className="fas fa-download mr-2"></i>
							Download
						</Button>}

						<Button variant="contained" onClick={this.handleClose} color="secondary" autoFocus>OK</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
