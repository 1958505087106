import * as React from "react";
import {setGlobLang, t} from "../common/lang";
import {observer} from 'mobx-react';

@observer export class Langbar extends React.Component<{show}, any> {
	constructor(props) {
		super(props)
	}

	setLang(l){
		setGlobLang(l)
	}
	render() {
		const {show} = this.props;
		return show && (
			<nav className="navbar navbar-expand-sm gm-langbar">
				<div className="container">

					<ul className="navbar-nav">
						<li><a href="#"><i className="fa fa-envelope-o"></i> Email</a></li>
						<li><a href="#"><i className="fa fa-phone-square"></i> Phone</a></li>
					</ul>

					<ul className="navbar-nav navbar-right">
						<li className="nav-item dropdown">
							<a href="#" className="dropdown-toggle" data-toggle="dropdown">
								<span className="badge badge-secondary gm-navbar-languagelabel2"><i className="gm-flag gm-flag-gb"></i></span>
								<span>{t('Language')}</span>
								<b className="caret"></b>
							</a>
							<ul className="dropdown-menu dropdown-menu-right">
								<li className="dropdown-header">{t('Choose the Language')}</li>
								<li className="dropdown-divider"></li>
								<li>
									<a className="nav-link" href="#" onClick={()=>this.setLang('en')}>
										<span className="badge badge-secondary gm-navbar-languagelabel"><i className="gm-flag gm-flag-gb"></i></span>
										<small>English</small>
									</a>
								</li>
								<li>
									<a className="nav-link" href="#" onClick={()=>this.setLang('ee')}>
										<span className="badge badge-secondary gm-navbar-languagelabel"><i className="gm-flag gm-flag-ee"></i></span>
										<small>{t('Estonian')}</small>
									</a>
								</li>
								<li>
									<a className="nav-link" href="#" onClick={()=>this.setLang('ru')}>
										<span className="badge badge-secondary gm-navbar-languagelabel"><i className="gm-flag gm-flag-ru"></i></span>
										<small>Russian</small>
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</nav>
		);
	}
}
