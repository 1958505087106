import * as React from "react";
import {MainAction} from "../../common/action-buttons";
import {uiState} from "../../common/ui-store";

const styles = {
	// button: {
	// 	margin: theme.spacing.unit,
	// },
	// leftIcon: {
	// 	marginRight: theme.spacing.unit,
	// },
	rightIcon: {
		marginLeft: '8px',
	},
	// iconSmall: {
	// 	fontSize: 20,
	// },
};

export const Slide2 = (props) => (
	<div className="carousel-item">
		<div className="jumbotron second">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<h1 className="text-center animated fadeInDown"><i>Easily Customizable For Your Needs</i></h1>
						<p className="lead text-center animated fadeInDown delay-1">Choose a color scheme for Navbar, Footer and Body.</p>
						<div className="text-center animated fadeInDown delay-2">
							{/*<a className="btn btn-color pull-center">Main Action</a>*/}
							<MainAction onClick={()=>uiState.toggleQuote()}>Quote!<i className="fas fa-quote-right ml-2"></i></MainAction>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

