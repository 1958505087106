import './Lics.scss'
import React, {Component} from 'react';
import {SectionTitle} from "../SectionTitle";
import {Tabs} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import {AckCard} from "../../common/ack-card/AckCard";
import {uiState} from "../../common/ui-store";
import {AckDialog} from "../../common/ack-card/AckDialog";
import {observer} from "mobx-react";
import {FancyLink} from "../../common/fancy-link/FancyLink";

@observer
export class Lics extends Component {
	state = {tab:0}
	onTab = (event, tab) => {
		this.setState({tab});
	};

	iso() {
		const isos = [
			{img:"iso/9001 ENG.jpg", pdf:"iso/9001 ENG.pdf", title:"ISO 9001:2015", desc:"Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
			{img:"iso/14001 ENG.jpg", pdf:"iso/14001 ENG.pdf", title:"ISO 14001:2015", desc:"Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
			{img:"iso/AQAP 2110 ENG.jpg", pdf:"iso/AQAP 2110 ENG.pdf", title:"AQAP 2110:2015", desc:"Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
		]
		return (
			<div className="card-row mt-4">
				{isos.map(a => <AckCard
					{...a}
					key={a.img}
					onClick={()=>uiState.toggleIso(a)}
				/>)}
				<AckDialog {...uiState.isoProps} toggle={()=>uiState.toggleIso()}/>
			</div>
		)
	}
	lics() {
		const l = [
			{title:'Hazardous Waste Handling License', desc:'', ico:'file-word', pdf:'lics/0380m4_GreenMarine_ohtlike_jäätmete-käitluslitsents.pdf'}
			, {title:'Environmental Complex License', desc:'', ico:'passport', pdf:'lics/Keskkonnakompleksluba.pdf'}
			, {title:'Waste Permit', desc:'Harjumaa', ico:'file', pdf:'lics/UUS_Harjumaa alates 06.02.2018 (kehtiv kuni 18.08.21).pdf'}
			, {title:'Waste Permit', desc:'Hiiumaa', ico:'file', pdf:'lics/UUS_Hiiumaa alates 22.02.2018 (kuni 21.02.2023).pdf'}
			, {title:'Waste Permit', desc:'Ida-Virumaa', ico:'file', pdf:'lics/UUS_Ida-Virumaa alates 13.03.18 (kehtiv kuni 17.09.19).pdf'}
			, {title:'Waste Permit', desc:'Lääne-Virumaa', ico:'file', pdf:'lics/UUS_LääneVirumaa alates 09.04.2018 (kehtiv kuni 01.03.2020).pdf'}
		];
		return (
			<div className="card-row mt-4">
				{l.map(a=>(
					<FancyLink key={a.pdf} {...a}></FancyLink>
				))}
			</div>
		);
	}
	policy() {
		return (
			<div className="mt-4">
				<h3>Environmental Policy</h3>

				<p>Our mission is marine ecology and environmental protection and we provide ships with an integrated waste management service in order to achieve this. We also have the resources required for the localisation and liquidation of marine pollution.</p>
				<p>The activities of AS Green Marine are aimed at the provision of a high-quality, environmentally friendly waste management service that meets the needs of our clients and at the creation of a working environment that meets all requirements.</p>
				<p>For this purpose, we offer the best possible logistic and technological solutions whilst considering the need to comprehensively reduce the burden placed on the environment by pollution. We proceed from the following principles:</p>
				<ul>
					<li>our work is organised in accordance with the laws and standards that regulate our activities;</li>
					<li>we advise and inform clients and cooperation partners about the standards established with laws and other legislative acts;</li>
					<li>we are constantly looking for new solutions and introducing the best possible technologies to guarantee a safe and resource-efficient waste management service;</li>
					<li>we support our partners in all environmentally friendly ventures;</li>
					<li>readiness to participate in the localisation and liquidation of marine pollution is a priority for us;</li>
					<li>we use every opportunity to have reusable waste recycled and make every effort to restrict waste depositing; and we use all the opportunities offered by feedback to assess our performance. We carry out regular reviews to guarantee that the company’s activities comply with the environmental policy.</li>
				</ul>
			</div>
		)
	}
	render() {
		const {tab} = this.state;
		return (
			<section id="lics">
				<div className='lics'>
					<div className="container">
						<div className="row">
							<div className="col">
								<SectionTitle main='Certification' sub='permits and Licenses'/>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<p>In April 2010 we successfully passed ISO 14001 certification audit by Bureau Veritas Eesti with scope of certification “Hazardous and non-hazardous waste management”. In 2013 the scope of certification was widened to "Hazardous and non-hazardous waste management. Recycling the waste containing petroleum, oil, liquid fuels and bituminous minerals".</p>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col">
								<Tabs value={tab} variant="fullWidth" onChange={this.onTab}>
									<Tab label="Certificates"/>
									<Tab label="Licenses"/>
									<Tab label="Policy"/>
								</Tabs>
								{tab === 0 && this.iso()}
								{tab === 1 && this.lics()}
								{tab === 2 && this.policy()}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}






