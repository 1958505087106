import {observer} from "mobx-react";
import React, {Component} from "react";
import {SectionTitle} from "../SectionTitle";

@observer
export class ExtraFee extends Component {
	render() {
		return (
			<section id="exfee">
				<div className='exfee'>
					<div className="container">
						<div className="row">
							<div className="col">
								<SectionTitle main='Services' sub='with extra fee'/>
							</div>
						</div>

						<div className="row">
						</div>
					</div>
				</div>
			</section>
		);
	}
}
