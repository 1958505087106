import {observable} from "mobx";
import {jlog} from "./utils";

export class UiStore {
	@observable showQuote = false;
	@observable showSidebar = false;
	@observable langNav = true;
	@observable ackProps = {
		open:false,
		img:'',
		title:''
	};
	@observable isoProps = {
		open:false,
		img:'',
		title:''
	};

	toggleQuote() {
		this.showQuote = !this.showQuote;
	}
	toggleSidebar() {
		this.showSidebar = !this.showSidebar;
	}
	showLangNav(s){
		this.langNav = s;
	}

	private toggleIt(data1, data2){
		const d = data1 || data2;
		const {open} = d;
		return {...d, open:!open}
	}
	toggleAck(data){
		this.ackProps = this.toggleIt(data,this.ackProps);
	}
	toggleIso(data?) {
		this.isoProps = this.toggleIt(data,this.isoProps);
	}
}

export const uiState = new UiStore();
