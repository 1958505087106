import * as _ from "lodash";

declare var chalk

export function asyncIt<T>(call: (serviceCallback: (error: any, result: T) => void) => void): Promise<T> {
	return new Promise<T>((resolve, reject) => {
		try {
			call((error, result) => {
				if (error) { reject(error); }
				resolve(result);
			});
		} catch (err) {
			reject(err);
		}
	});
}

export async function main(f:()=>Promise<any>, exit:boolean = false){
	try {
		await f();
	} catch (e) {
		exErr(e)
		err(JSON.stringify(e))
	}
	if (exit){
		process.exit();
	}
}

export const log = console.log;

export function exErr(e){
	console.error(chalk.bgRed(e.message))
	console.error(e.stack)
}

export function err(a?:any){
	console.error(chalk.bgRed(a))
}

export function jlog(a:any, pretty = false){
	if (pretty){
		console.log(JSON.stringify(a, null, 2))
	} else {
		console.log(JSON.stringify(a))
	}
}

export function jlogc(a:any, chalk, pretty = false){
	let fmt = pretty
		? JSON.stringify(a, null, 2)
		: JSON.stringify(a)
		;
	console.log(chalk(fmt))
}

export function timeRoundDown(dt:Date, min:number){
	var interval = min * 60 * 1000;
	return new Date(Math.floor(dt.getTime()/interval)*interval);
}
export function timeRoundUp(dt:Date, min:number){
	var interval = min * 60 * 1000;
	return new Date(Math.ceil(dt.getTime()/interval)*interval);
}
export function dtKey(d:Date){
	return d.toISOString().replace(/[-T:Z]+/g,'').substr(0,12)
}

export function topRank(l:any[], f):any{
	let q = _(l).countBy(f).toPairs().orderBy(a=>a[1],['desc']).head();
	return q[0];
}

export function numOrNull(v){
	let f = parseFloat(v);
	if (_.isNaN(f) || _.isUndefined(f) || _.isNull(f)){
		return null;
	}
	return f;
}

export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export async function retry(n, msDelay, f){
	let r = null;
	for (let i = 0; i < n && (_.isNull(r) || _.isEmpty(r) || _.isUndefined(r)); ++i){
		r = await f();
		await sleep(500);
	}
	return r;
}

export function avg(val1, val2){
	let v1 = numOrNull(val1) || 0;
	let v2 = numOrNull(val2) || 0;
	if (v1 === 0) {
		return v2;
	}
	return (v1+v2)*0.5;
}
