import * as React from "react";
import {indigo, orange} from "@material-ui/core/colors";
import {slideButton} from "../../common/styles";
import {MainAction} from "../../common/action-buttons";

const style = {
	...slideButton,
	background: indigo['600'],
	boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
};

const fab = {
	background: orange['500'],
	boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
};

export const Slide3 = (props) => (
	<div className="carousel-item">
		<div className="jumbotron third">
			<div className="container">
				<div className="row">
					<div className="col-md">
						<h1 className="animated fadeInLeftBig">
							<i>We are always open</i><br/>
							<i>for your questions</i><br/>
							<i>and feature requests.</i>
						</h1>
						<p className="lead animated fadeInLeftBig delay-1">
							<i>We are always open</i><br/>
							<i>for your questions</i><br/>
							<i>and feature requests.</i>
						</p>

						{/*<a className="btn btn-color animated fadeInLeftBig delay-2">Main Action</a>*/}

						<div className="animated fadeInLeftBig delay-2">
							<div className="row">
								<div className="col">
									<MainAction>More</MainAction>
								</div>
								{/*<div className="col">*/}
								{/*	<Fab color="secondary" aria-label="add" style={fab}>*/}
								{/*		<AddIcon />*/}
								{/*	</Fab>*/}

								{/*	/!*<Button variant="fab" color="secondary" aria-label="edit">*!/*/}
								{/*	/!*<Icon>alarm</Icon>*!/*/}
								{/*	/!*</Button>*!/*/}

								{/*	/!*<Button style={style} variant="raised" color="secondary" size="large">*!/*/}
								{/*	/!*Main Action*!/*/}
								{/*	/!*<Icon className="ml-2">local shipping</Icon>*!/*/}
								{/*	/!*</Button>*!/*/}
								{/*</div>*/}
							</div>
						</div>
					</div>
					<div className="col-md">
					</div>
				</div>
			</div>
		</div>
	</div>
);

