import './AckCard.scss';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


export const AckCard = ({img, title, desc, onClick}) => {
	return (
		<Card className="card" onClick={onClick}>
			<CardActionArea>
				<CardMedia
					className='media'
					image={img}
					title={title}
					style={{backgroundPosition:'top'}}
				/>
			</CardActionArea>

			<CardContent>
				<h5>{title}</h5>
				<p>{desc}</p>
				{/*<Typography gutterBottom variant="h5" component="h2">*/}
				{/*  Lizard*/}
				{/*</Typography>*/}
				{/*<Typography component="p">*/}
				{/*  Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging*/}
				{/*  across all continents except Antarctica*/}
				{/*</Typography>*/}
			</CardContent>

			{/*<CardActions>*/}
			{/*  <Button size="small" color="primary">*/}
			{/*    Share*/}
			{/*  </Button>*/}
			{/*  <Button size="small" color="primary">*/}
			{/*    Learn More*/}
			{/*  </Button>*/}
			{/*</CardActions>*/}
		</Card>
	);
};

// export const AckCard = withStyles(styles)(AckCardNoStyle);

