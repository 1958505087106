import * as React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {red, deepOrange, orange, purple, indigo} from "@material-ui/core/colors";
import {slideButton} from "../../common/styles";

const style = {
	...slideButton,
	background: deepOrange['500'],
	boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
};

export const Slide4 = (props) => (
	<div className="carousel-item">
		<div className="jumbotron _4">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<h1 className="text-center animated fadeInDown"><i>Green Marine Ltd</i></h1>
						<p className="lead text-center animated fadeInDown delay-1">Green Marine Ltd. is a joint venture of Port of Tallinn Ltd.</p>
						<div className="text-center animated fadeInDown delay-2">
							<Button style={style} variant="contained" color="secondary" size="large">
								<span>More</span>
								<Icon className="ml-2">info</Icon>
							</Button>
						</div>
						{/*<h1>*/}
							{/*<img className="img-responsive hidden-xs center-block animated fadeInDown delay-3" src="/images/Ikoon_recycling.png" alt="..." />*/}
						{/*</h1>*/}
					</div>
				</div>
			</div>
		</div>
	</div>

);

